// src/components/WalletFeatures.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const WalletFeatures = () => {

  return (
    <section className="section bg-theme-grad section-about tc-light">
        <div className="container overflow-hidden">
            <ScrollAnimation className="section-head text-center wide-auto-sm" animateIn="fadeInUp">
                <h2 className="title title-lg tc-white">Wallet Features</h2>
                <p className="">DeFI Wallet provides a powerful, secure app service that allows you to manage your all cryptocurrency and make reliable and transparent.</p>
            </ScrollAnimation>
            <div className="nk-block">
                <div className="row gutter-vr-30px align-items-center justify-content-center justify-content-lg-between text-center text-lg-start">
                    <div className="col-sm-10 col-lg-6 order-lg-last">
                        <ScrollAnimation className="nk-block-gfx ms-lg-4" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-b.png'} alt="" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-sm-10 col-lg-5">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <em className="icon icon-lg icon-round ikon ikon-cash mb-4"></em>
                            <h4 className="title title-md">EARN</h4>
                            <h6>Earn rebates on 25+ token.</h6>
                            <h6>Enjoy great returns with flexible terms.</h6>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <div className="row gutter-vr-30px align-items-center justify-content-center justify-content-lg-between text-center text-lg-start">
                    <div className="col-sm-10 col-lg-6">
                        <ScrollAnimation className="nk-block-gfx me-lg-4" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-sm-g.png'} alt="" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-sm-10 col-lg-5">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <em className="icon icon-lg icon-round ikon ikon-paricle mb-4"></em>
                            <h4 className="title title-md">Browse DApps</h4>
                            <h6>Explore the most popular DApps seamlessly from within the App.</h6>
                            <h6>Seamlessly connect with, swap, farm, and mine on the hottest DeFi protocols.</h6>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="nk-block pdt-l">
                <div className="row gutter-vr-30px align-items-center justify-content-center justify-content-lg-between text-center text-lg-start">
                    <div className="col-sm-10 col-lg-6 order-lg-last">
                        <ScrollAnimation className="nk-block-gfx me-lg-4" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-d.png'} alt="" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-sm-10 col-lg-5">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <em className="icon icon-lg icon-round ikon ikon-wallet-digital mb-4"></em>
                            <h4 className="title title-md">Swap</h4>
                            <h6>Swap 1000+ tokens across multiple chains, including Ethereum, Cronos, Cosmos and more.</h6>
                            <h6>Migrate assets between different chains and KYBERULTRA with the in-app bridging feature.</h6>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="nk-block pdt-r">
                <div className="row gutter-vr-30px align-items-center justify-content-center justify-content-lg-between text-center text-lg-start">
                    <div className="col-sm-10 col-lg-6">
                        <ScrollAnimation className="nk-block-gfx me-lg-4" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/azalea/gfx-e.png'} alt="" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-sm-10 col-lg-5">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <em className="icon icon-lg icon-round ikon ikon-cloud mb-4"></em>
                            <h4 className="title title-md">Store</h4>
                            <h6>Gain full control over your crypto and your keys.</h6>
                            <h6>Easily manage 1000+ tokens across 30+ blockchains, including KyberUltra ,Stablecoin, Bitcoin, and Ethereum.</h6>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="nk-block pdt-s">
                <div className="row gutter-vr-30px align-items-center justify-content-center justify-content-lg-between text-center text-lg-start">
                    <div className="col-sm-10 col-lg-6 order-lg-last">
                        <ScrollAnimation className="nk-block-gfx me-lg-4" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-c.png'} alt="" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-sm-10 col-lg-5">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <em className="icon icon-lg icon-round ikon ikon-document mb-4"></em>
                            <h4 className="title title-md">Send</h4>
                            <h6>Send crypto at your preferred confirmation speed and network fee.</h6>
                            <h6>Our ETH Gas Meter gives you real-time updates on gas fees and transaction speeds.</h6>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <div className="row gutter-vr-30px align-items-center justify-content-center justify-content-lg-between text-center text-lg-start">
                    <div className="col-sm-10 col-lg-6">
                        <ScrollAnimation className="nk-block-gfx me-lg-4" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/dark/gfx-z-c.png'} alt="" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-sm-10 col-lg-5">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <em className="icon icon-lg icon-round ikon ikon-donught mb-4"></em>
                            <h4 className="title title-md">Wallet Extension</h4>
                            <p>Seamlessly connect your DeFi Wallet</p>
                            <p>App to a desktop browser.</p>
                            <h6>Send and receive crypto, view balances, and easily confirm  DApp transactions. All this without having to switch devices.</h6>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default WalletFeatures;
