import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Support from "./pages/Support";
import Exchange from './pages/Exchange';
import KyberUltra from './pages/KyberUltra';
import Wallet from './pages/Wallet';
import Spend from './pages/Spend';
import Chat from './pages/Chat';
import Services from './pages/Services';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <div className='nk-body body-wider theme-dark mode-onepage'>
        <ScrollToTop />
        <Routes basename={process.env.PUBLIC_URL}>
          <Route exact path='/' element={<Home/>} />
          <Route path='/about' element={<AboutUs/>} />
          <Route path='/support' element={<Support/>} />
          <Route path='/exchange' element={<Exchange/>} />
          <Route path='/kyberultra' element={<KyberUltra/>} />
          <Route path='/wallet' element={<Wallet/>} />
          <Route path='/spend' element={<Spend/>} />
          <Route path='/chat' element={<Chat/>} />
          <Route path='/services' element={<Services/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
