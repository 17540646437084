// src/components/Chat.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Particle from "../components/Particle";
import Security from "../components/Security";
import Why from "../components/Why";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ScrollAnimation from "react-animate-on-scroll";

const Chat = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  return (
    <div className="Chat-page nk-wrap">
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
        id="header"
      >
        <Header />

        <div className="header-banner bg-theme-grad">
          <div className="nk-banner">
            <div className="banner banner-page pb-0">
              <div className="banner-wrap">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                      <div className="banner-caption cpn tc-light text-center">
                        <ScrollAnimation
                          className="cpn-head"
                          animateIn="fadeInUp"
                          initiallyVisible={true}
                        >
                          <h2 className="title ttu">KyberChat</h2>
                          <p className="">
                            KyberChat is a secure communication platform with
                            post-quantum encryption for messaging, video
                            calling, and file sharing.
                          </p>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-ovm bg-blend before-bottom before-h70 shape-o shape-contain shape-center-top"></div>

          <Particle />
        </div>
      </header>

      <main className="nk-pages">
        <Security />
        <Why />

        <section className="section bg-theme tc-light" id="faqs">
          <div className="transform-line"></div>
          <div className="container">
            <ScrollAnimation
              className="section-head text-center wide-auto-sm"
              animateIn="fadeInUp"
            >
              <h2 className="title title-s4" title="FAQS">
                Frequently Asked Questions
              </h2>
              <p className="">
                Here's what people are asking us about KyberChat.
              </p>
            </ScrollAnimation>
            <div className="nk-block overflow-hidden">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10">
                  <ul className="nav tab-nav tab-nav-line mgb-r"></ul>
                  <ScrollAnimation className="tab-content" animateIn="fadeInUp">
                    <div
                      className="tab-pane fade show active"
                      id="general-questions-19"
                    >
                      <div
                        className="accordion accordion-s1 accordion-faq"
                        id="faq-67"
                      >
                        <div className="accordion-item accordion-item-s4 bg-theme-alt">
                          <h5
                            className="accordion-title"
                            data-bs-toggle="collapse"
                            data-bs-target="#faq-67-1"
                          >
                            {" "}
                            WHAT IS POST-QUANTUM ENCRYPTION?{" "}
                            <span className="accordion-icon accordion-icon-s2"></span>
                          </h5>
                          <div
                            id="faq-67-1"
                            className="collapse show"
                            data-bs-parent="#faq-67"
                          >
                            <div className="accordion-content">
                              <p className="text-justify">
                                Post-quantum encryption prevents quantum
                                computers from being used to access your data.
                                RSA and ECC are currently secure, but quantum
                                computers using qubits could eventually break
                                modern encryption, jeopardizing data security.
                                Using complex mathematical equations,
                                post-quantum encryption resists the power of
                                quantum computers.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item accordion-item-s4 bg-theme-alt">
                          <h5
                            className="accordion-title collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#faq-67-2"
                          >
                            {" "}
                            WHAT ENCRYPTION DOES KYBERCHAT USE?{" "}
                            <span className="accordion-icon accordion-icon-s2"></span>
                          </h5>
                          <div
                            id="faq-67-2"
                            className="collapse"
                            data-bs-parent="#faq-67"
                          >
                            <div className="accordion-content">
                              <p className="text-justify">
                                KyberChat employs a multi-layered encryption
                                scheme. It encrypts communication data with
                                AES-GCM and strengthens it using an advanced
                                implementation of elliptic-curve Diffie-Hellman
                                schemes with the CRYSTALS-Kyber post-quantum
                                protocol. For further details on the security
                                protocols, you can request our whitepaper.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item accordion-item-s4 bg-theme-alt">
                          <h5
                            className="accordion-title collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#faq-67-3"
                          >
                            {" "}
                            WHICH OPERATING SYSTEMS IS KYBERCHAT AVAILABLE ON?{" "}
                            <span className="accordion-icon accordion-icon-s2"></span>
                          </h5>
                          <div
                            id="faq-67-3"
                            className="collapse"
                            data-bs-parent="#faq-67"
                          >
                            <div className="accordion-content">
                              <p className="text-justify">
                                KyberChat will be available for desktop on
                                Windows, Mac, Linux. KyberChat mobile apps will
                                be released on iOS and Android. Multi-device
                                functionality for your account will be available
                                once the mobile apps are released.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="nk-ovm nk-ovm-fixed shape-z5">
        <div className="ovm-line"></div>
      </div>

      <footer className="nk-footer bg-theme-alt section-connect">
        <Subscibe />
        <Footer />
      </footer>

      <Preloader />
    </div>
  );
};

export default Chat;
