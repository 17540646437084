// src/components/Spend.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Particle from "../components/Particle";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ScrollAnimation from "react-animate-on-scroll";

const Spend = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  return (
    <div className="Spend-page nk-wrap">
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
        id="header"
      >
        <Header />

        <div className="header-banner bg-theme-grad">
          <div className="nk-banner">
            <div className="banner banner-page pb-0">
              <div className="banner-wrap">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                      <div className="banner-caption cpn tc-light text-center">
                        <ScrollAnimation
                          className="cpn-head"
                          animateIn="fadeInUp"
                          initiallyVisible={true}
                        >
                          <h2 className="title ttu">Stay Tuned!</h2>
                          <p>
                            We are pleased to announce that we are actively
                            developing and building a Crypto Debit Card Program.
                          </p>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-ovm bg-blend before-bottom before-h70 shape-o shape-contain shape-center-top"></div>

          <Particle />
        </div>
      </header>

      <main className="nk-pages">
        <section className="section section-team bg-theme">
          <div className="container overflow-hidden">
            <div className="nk-block nk-block-team-featured team-featured">
              <div className="row align-items-center">
                <div className="col-lg-7 mb-4 mb-lg-0">
                  <ScrollAnimation className="gfx py-4" animateIn="fadeInUp">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/dark/gfx-z-c.png"
                      }
                      alt="gfx"
                    />
                  </ScrollAnimation>
                </div>
                <div className="col-lg-5">
                  <ScrollAnimation
                    className="team-featured-cont"
                    animateIn="fadeInUp"
                  >
                    <h6 className="title title-xs text-white">
                      {" "}
                      - No Annual Fees
                    </h6>
                    <h6 className="title title-xs text-white">
                      {" "}
                      - The only crypto Card you need
                    </h6>
                    <h6 className="title title-xs text-white">
                      {" "}
                      - Top up with Crypto{" "}
                    </h6>

                    <p className="text-justify">
                      Free ATM Withdrawal per month: $1000
                    </p>
                    <p className="text-justify">
                      ATM Withdrawal Monthly Limit: $10000
                    </p>
                    <p className="text-justify">
                      Aggregated Top-up Monthly Limit: $25000
                    </p>
                  </ScrollAnimation>
                </div>
                <ScrollAnimation>
                  <p className="text-center" animateIn="fadeInUp">
                    Reserve and manage your ShieldTech Crypto Debit card in the
                    ShieldTech wallet App.
                  </p>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          <div className="nk-ovm shape-a-sm ov-h"></div>
        </section>

        <section className="section bg-dark tc-light">
          <div className="container overflow-hidden">
            <div className="nk-block nk-block-feature">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-mb-10">
                  <ScrollAnimation
                    className="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap bg-theme-alt"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-sm">
                        Is the ShieldTech Card a credit or debit card?{" "}
                      </h5>
                      <p className="text-justify">
                        The <b>ShieldTech Card</b> is a prepaid card. Broadly
                        speaking, prepaid cards are the same as debit cards. The
                        difference is that debit cards are linked to your bank
                        account, but prepaid cards need to be topped up. In our
                        case, you can top up using cryptocurrency.
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-6 col-mb-10">
                  <ScrollAnimation
                    className="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap bg-theme-alt"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-sm">
                        How do I apply for a ShieldTech Card?{" "}
                      </h5>
                      <p className="text-justify">
                        The Midnight Blue <b>ShieldTech Card</b> is available
                        without the need to lockup KYB tokens. To apply for our
                        Platinum or a BLACK, <b>ShieldTech Cards</b> you need to
                        lockup KYB tokens for a period of 180 days.{" "}
                      </p>
                      <p>
                        Platinum Shield Card: <b>175.000</b> KYB Locked
                        <br />
                        Black Shield Card: <b>500.000</b> KYB locked
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="nk-block nk-block-feature pdt-0">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-mb-10">
                  <ScrollAnimation
                    className="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap bg-theme-alt"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-sm">
                        How do I top-up my card?{" "}
                      </h5>
                      <p className="text-justify">
                        You can top up your card using your Crypto Wallet, in
                        the ShieldTech wallet App. Go to the ‘Card’ tab, tap
                        ‘Top Up’, and select your preferred method. Please visit
                        our Help Centre for more information about how to set up
                        your Fiat Wallet.
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-6 col-mb-10">
                  <ScrollAnimation
                    className="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap bg-theme-alt"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-sm">
                        Which exchange rate will you use?{" "}
                      </h5>
                      <p className="text-justify">
                        We have competitive exchange rates for both fiat and
                        cryptocurrencies. The exact rates depend on your
                        jurisdiction and card tier. We will provide all these
                        details in the <b>ShieldTech Card</b> Terms and
                        Conditions when you apply for your card.
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="nk-block nk-block-feature pdt-0">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-mb-10">
                  <ScrollAnimation
                    className="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap bg-theme-alt"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text" animateIn="fadeInUp">
                      <h5 className="title title-sm">
                        Are there any fees I should be aware of?{" "}
                      </h5>
                      <p className="text-justify">
                        Information about fees and limits (i.e. free ATM
                        withdrawals, interbank exchange rates, top-ups, etc.)
                        can be found in the ‘Fees & Limits’ section under
                        ‘Settings’ in the <b>ShieldTech Card</b> App. Fees vary
                        by card tier, so be sure you’re checking the correct
                        one.
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-6 col-mb-10">
                  <ScrollAnimation
                    className="feature feature-inline card card-s3 flex-wrap flex-sm-nowrap bg-theme-alt"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <p className="text-justify">
                        Cardholders cannot load cryptocurrency onto their{" "}
                        <b>ShieldTech Card</b>. All cryptocurrency will be
                        converted to the respective market's currency and can be
                        loaded onto the <b>ShieldTech Card</b> for use in
                        purchase and ATM withdrawals.
                      </p>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="nk-ovm nk-ovm-fixed shape-z5">
        <div className="ovm-line"></div>
      </div>

      <footer className="nk-footer bg-theme-alt section-connect">
        <Subscibe />
        <Footer />
      </footer>

      <Preloader />
    </div>
  );
};

export default Spend;
