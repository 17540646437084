// src/components/Vission.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Vission = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section ov-h">
        <div className="nk-block nk-block-features">
            <div className="container">
                <div className="row align-items-center gutter-vr-30px">
                    <div className="col-lg-4">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <h2 className="title title-thin text-white">Why IT Matters</h2>
                            <p className="">Powered by cryptocurrency, the forthcoming internet era known as <b>WEB3</b> promises increased fairness and equity, being owned by both creators and users (YOU).</p>
                            <p className="">We strongly believe that it is your basic right to control over your finances, data and identity.</p>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-8">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <ScrollAnimation className="feature card card-s2 ov-h" animateIn="fadeInUp">
                                    <div className="feature-text">
                                        <h5 className="title title-md">Our Vision</h5>
                                        <p>Cryptocurrency in Every Wallet</p>
                                        <p>Empower individuals through decentralized technologies</p>
                                    </div>
                                    <div className="feature-icon feature-icon-bg"><em className="icon ikon ikon-paricle-alt tc-info pt-4"></em></div>
                                </ScrollAnimation>
                            </div>
                            <div className="col-sm-6">
                                <ScrollAnimation className="feature card card-s2 ov-h p-5" animateIn="fadeInUp">
                                    <div className="feature-text">
                                        <h5 className="title title-md">Our Mission</h5>
                                        <p>Accelerate the world's transition to cryptocurrency</p>
                                        <p>
                                            Introducing innovative
                                            solutions, overcoming
                                            barriers, and fostering
                                            widespread awareness and
                                            understanding of the benefits
                                            of cryptocurrency in the
                                            broader financial landscape. 
                                        </p>
                                    </div>
                                    <div className="feature-icon feature-icon-bg"><em className="icon ikon ikon-bulb-2 tc-info pt-4"></em></div>
                                </ScrollAnimation>
                                <ScrollAnimation className="feature card card-s2 ov-h p-5" animateIn="fadeInUp">
                                    <div className="feature-text">
                                        <h5 className="title title-md">Our Partners</h5>
                                        <p>Reaching the next billion users with the world's best partners.</p>
                                        <p>
                                            Expanding our user base to
                                            the next billion individuals
                                            through strategic
                                            partnerships with the world's
                                            foremost collaborators.
                                        </p>
                                    </div>
                                    <div className="feature-icon feature-icon-bg"><em className="icon ikon ikon-user tc-info pt-4"></em></div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Vission;
