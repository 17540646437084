// src/components/Security.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Security = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  return (
    <section className="section bg-dark-alt tc-light">
      <div className="container overflow-hidden">
        <ScrollAnimation
          className="section-head text-center wide-auto-md wide-auto"
          animateIn="fadeInUp"
          initiallyVisible={true}
        >
          <h6>TEAM COMMUNICATION. WE GOT IT SECURED.</h6>
          <p>
            Ensure your teams remain connected while protecting all shared
            content in messages, video calls, and file transfers.
          </p>
          <h2 className="title">WITH KYBERCHAT</h2>
          <h6>✓ SECURITY COMES FIRST</h6>
        </ScrollAnimation>
        <div className="nk-block nk-block-features-s3">
          <div className="row justify-content-center my-4">
            <ScrollAnimation
              className="section-head text-center wide-auto-md wide-auto"
              animateIn="fadeInUp"
              initiallyVisible={true}
            >
              <h6>ENTERPRISE CONTROL</h6>
              <p>
                We offer two distinct plan options for Secure Team
                Collaboration.
              </p>
            </ScrollAnimation>
            <div className="col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-text text-start">
                  <p>
                    1: A Secure Suite, A fully Dedicated controlled Phone for
                    Enterprises.
                  </p>
                  <p>This is the most secure option we have and offer.</p>
                  <p>Contact us for more info and details.</p>
                  <p>
                    Option is Live on android , soon live on more OS systems.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature-s1 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-text text-start">
                  <p>
                    2: A Secure application created to communicate securely in a
                    team.
                  </p>
                  <p>Inbuild VPN in the application.</p>
                  <p>Sandbox (encrypted Folder)</p>
                  <p>
                    Downloading from the website and purchase License key from
                    there Website.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row justify-content-center my-4 pt-4">
            <ScrollAnimation
              className="section-head text-center wide-auto-md wide-auto"
              animateIn="fadeInUp"
              initiallyVisible={true}
            >
              <h2 className="title">
                The Technical specifications of our KyberChat are as follows:
              </h2>
            </ScrollAnimation>
            <div className="col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt animated"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-shiled"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">
                    SECURITY FROM END-TO-END
                  </h4>
                  <ScrollAnimation className="text-start" animateIn="fadeInUp">
                    <ul class="list list-dot">
                      <li class="text-white">1. X4DH</li>
                      <p class="p-0 m-0">
                        Key exchange involves the combines use of four
                        Elliptic-Curve Diffie-Hellman schemes (ECDH).
                      </p>
                    </ul>
                  </ScrollAnimation>
                  <ScrollAnimation className="text-start" animateIn="fadeInUp">
                    <ul class="list list-dot">
                      <li class="text-white">2. DOUBLE RATCHET</li>
                      <p>
                        Provides backward and forward secrecy for communication
                        data with a regularly renewing shared secret.
                      </p>
                    </ul>
                  </ScrollAnimation>
                  <ScrollAnimation className="text-start" animateIn="fadeInUp">
                    <ul class="list list-dot">
                      <li class="text-white">3. POST-QUANTUM</li>
                      <p>
                        Future-proof data protection using CRYSTALS-Kyber
                        post-quantum protocol for key encapsulation.
                      </p>
                    </ul>
                  </ScrollAnimation>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-safety"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">
                    DATA SOVEREIGNTY
                  </h4>
                  <p className="text-start">
                    YOU CONTROL THE KEYS AND THE COMMUNICATION.
                  </p>
                  <p className="text-start">
                    Kyber helps you keep control of your data security.
                  </p>
                  <p className="text-start">
                    No third party, including Kyber Team, has no access to your
                    team's communications.
                  </p>
                  <ul class="list list-dot text-start">
                    <li class="text-white">PRIVATE KEYS STORED ON DEVICE</li>
                    <li class="text-white">LOCAL ENCRYPTED DATA STORAGE</li>
                    <li class="text-white">DATA RETENTION AND USE POLICIES</li>
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-user-chat"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">
                    POST-QUANTUM READINESS
                  </h4>
                  <p>MIGRATE TEAM COMMUNICATION TO POST-QUANTUM SECURITY</p>
                  <p>
                    Using CRYSTALS-Kyber to protect your key exchanges, protect
                    your team's communication data today against quantum-enabled
                    cyberattacks.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-data-server"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">
                    SECURED STORED DATA
                  </h4>
                  <p>PROTECT YOUR TEAM'S INFORMATION STORED IN KYBER</p>
                  <p>
                    Your communication data isn't stored on KYBER servers, so
                    information sharing starts and ends where it's supposed to.
                    Then it's protected the way you need to.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-offset-3 col-md-offset-3 col-lg-6 col-md-6">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-user-setting"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">
                    PASSWORD PROTECTED
                  </h4>
                  <p>SALT & ARGON2 PASSWORD HASHING</p>
                  <p>AES-GCM FOR DATA ENCRYPTION</p>
                  <p>PASSWORD ATTEMPTS LIMIT & INACTIVITY TIMEOUT</p>
                  <p>ADVANCED BRUTE-FORCE PROTECTION</p>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="row justify-content-center my-4 pt-4">
            <ScrollAnimation
              className="section-head text-center wide-auto-md wide-auto"
              animateIn="fadeInUp"
              initiallyVisible={true}
            >
              <h2 className="title">MORE ABOUT KYBER SECURITY</h2>
            </ScrollAnimation>
            <div className="col-lg-4 col-md-4">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-cloud-org"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">NO INTEGRATIONS</h4>
                  <p>
                    Uncompromising security means eliminating vulnerable entry
                    points and keeping the flow of data secured in KYBER.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-4 col-md-4">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-bulb-2"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">
                    PRIVATE WORKSPACE
                  </h4>
                  <p>
                    Disable the ability for your team members to connect with
                    external Kyber contacts, ensuring only verified connections
                    on KYBER.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-4 col-md-4">
              <ScrollAnimation
                className="feature feature-s3 feature-center card bg-theme-alt"
                animateIn="fadeInUp"
              >
                <div className="feature-icon w-100">
                  <em className="icon icon-lg ikon ikon-clip-board"></em>
                </div>
                <div className="feature-text">
                  <h4 className="title title-md title-dark">UNIQUE KYBER ID</h4>
                  <p>
                    Unique contact IDs generated by KYBER. No phone numbers or
                    emails reduces the risk of phishing, spoofing, and spam.
                  </p>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Security;
