// src/components/Roadmap.js
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Roadmap = () => {
  return (
    <section className="section tc-light section-connect" id="roadmap">
      <div className="container ov-h">
        <div className="section-head text-center wide-auto-md-sm">
          <ScrollAnimation animateIn="fadeInUp">
            <h2 className="title title-s4" title="Roadmap">
              Shield Tech Roadmap
            </h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp">
            <p>
              Our current roadmap focuses on WEB3 apps and protocols related to
              security and financial aspects. Moving forward, we will
              continuously explore additional opportunities. Our specialization
              lies in all aspects of security, ranging from securing crypto
              exchanges to facilitating staking and payment gateways.
            </p>
          </ScrollAnimation>
        </div>

        <div className="nk-block nk-block-roadmap">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="roadmap-wrap">
                <div className="roadmap-line"></div>
                <div className="roadmap">
                  <div className="roadmap-year">2023</div>
                </div>
                <ScrollAnimation
                  className="accordion roadmap roadmap-left roadmap-finished"
                  animateIn="fadeInUp"
                >
                  <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-60-1"
                      >
                        {" "}
                        SHIELD TECH BLOCKCHAIN
                      </h5>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  className="accordion roadmap roadmap-right roadmap-finished"
                  animateIn="fadeInUp"
                >
                  <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-64-1"
                      >
                        {" "}
                        EXCHANGE
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                    </div>

                    <div
                      id="faq-64-1"
                      className="collapse"
                      data-bs-parent="#faq-64"
                    >
                      <ul className="list list-dot">
                        <li className="accordion-content text-white text-justify px-5">
                          Our decentralized exchange (DEX) operates as a
                          peer-to-peer exchange on our network of nodes,
                          facilitating the seamless exchange of various
                          cryptocurrencies across multiple blockchain networks,
                          all while keeping exchange fees to a minimum and
                          without imposing any KYC requirements.
                        </li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
                <div className="roadmap">
                  <div className="roadmap-year">2023</div>
                </div>
                <ScrollAnimation
                  className="accordion roadmap roadmap-left roadmap-finished"
                  animateIn="fadeInUp"
                >
                  <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-61-1"
                      >
                        {" "}
                        ENCRYPTED-CHAT
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                    </div>
                    <div
                      id="faq-61-1"
                      className="collapse"
                      data-bs-parent="#faq-61"
                    >
                      <ul className="list list-dot">
                        <li className="accordion-content text-white text-justify px-5">
                          We've developed a decentralized, exceptionally secure,
                          and anonymous chat platform based on our blockchain
                          technology. We will provide subscription options for
                          both Lite and Pro versions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  className="accordion roadmap roadmap-right"
                  animateIn="fadeInUp"
                >
                  <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-66-1"
                      >
                        {" "}
                        WALLET SHIELD-MANAGER
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                    </div>
                    <div
                      id="faq-66-1"
                      className="collapse"
                      data-bs-parent="#faq-66"
                    >
                      <ul className="list list-dot">
                        <li className="accordion-content text-white text-justify px-5">
                          Manage with maximum security and trade with your
                          Bitcoin,Shieldcoin, Ethereum, stablecoins and other
                          tokens in your wallet.
                        </li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  className="accordion roadmap roadmap-left"
                  animateIn="fadeInUp"
                >
                  <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-65-1"
                      >
                        {" "}
                        CRYPTO DEBIT CARD
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                    </div>
                    <div
                      id="faq-65-1"
                      className="collapse"
                      data-bs-parent="#faq-65"
                    >
                      <ul className="list list-dot">
                        <li className="accordion-content text-white text-justify px-5">
                          To make a payment using a credit card, converting from
                          cryptocurrency to traditional fiat currency, supported
                          by the wallet and cryptocurrency funds stored in
                          ShieldWallet.
                        </li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  className="accordion roadmap roadmap-right"
                  animateIn="fadeInUp"
                >
                  <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-63-1"
                      >
                        {" "}
                        STAKE AND EARN
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                    </div>
                    <div
                      id="faq-63-1"
                      className="collapse"
                      data-bs-parent="#faq-63"
                    >
                      <ul className="list list-dot">
                        <li className="accordion-content text-white text-justify px-5">
                          Participate in our 'Stake and Earn' program, which
                          allows you to lock various cryptocurrencies, including
                          USDT, Ethereum, BTC, and more, in your Shield Wallet,
                          enabling you to generate passive income. Your coins
                          will remain securely locked in your own wallet without
                          the need for thirdparty storage.
                        </li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  className="accordion roadmap roadmap-left"
                  animateIn="fadeInUp"
                >
                  {/* <div className="roadmap-step accordion-item accordion-item-s4 bg-theme-alt py-2 px-2">
                    <div className="roadmap-head">
                      <h5
                        className="accordion-title title-semibold collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-62-1"
                      >
                        {" "}
                        VPN
                        <span className="accordion-icon accordion-icon-s2"></span>
                      </h5>
                    </div>
                    <div
                      id="faq-62-1"
                      className="collapse"
                      data-bs-parent="#faq-62"
                    >
                      <ul className="list list-dot">
                        <li className="accordion-content text-white text-justify px-5">
                          Our VPN operates on our own proprietary blockchain
                          network, providing enhanced internet security. It can
                          also be adapted to secure various internet protocols
                          and is resistant to post-quantum threats.
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
