// src/components/KyberUltra.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Particle from "../components/Particle";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ScrollAnimation from 'react-animate-on-scroll';

const KyberUltra = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <div className="KyberUltra-page nk-wrap">

        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
            <Header />
            
            <div className="header-banner bg-theme-grad">
                <div className="nk-banner">
                    <div className="banner banner-page pb-0">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <ScrollAnimation className="cpn-head" animateIn="fadeInUp" initiallyVisible={true}>
                                                <h2 className="title ttu">KYBERULTRA</h2>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm bg-blend before-bottom before-h70 shape-o shape-contain shape-center-top"></div>

                <Particle />
            </div>
        </header>

        <main className="nk-pages">
            <section className="section pdt-l pb-0 tc-light bg-theme-grad">
                <div className="container overflow-hidden">
                    <div className="nk-block">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-mb-10">
                                <div className="nk-block-text text-center">
                                    <div className="feature-icon feature-icon-md-s2 mx-auto">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/icons/icon-t2.png'} className="" alt="" />
                                    </div>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <h2 className="title title-md">What Is KyberUltra Token (KCS)?</h2>
                                    </ScrollAnimation>
                                    <ScrollAnimation className="feature-text" animateIn="fadeInUp">
                                        <p className="fz-5">KYB is the native token of ShieldTech, which was launched in 2023 as a profit-sharing token . It was issued as an ERC-20 token running on or own EVM Network and is supported by most Ethereum wallets.</p>
                                    </ScrollAnimation>
                                    <div className="hr mgy-r"></div>
                                    <ScrollAnimation className="feature-text" animateIn="fadeInUp">
                                        <p className="fz-5">The total supply of KYB was set at 400 million. Sooner or later, as the ShieldTech decentralized trading and financial solutions goes live, KYB will be the native asset of ShieldTech decentralized financial services as well as the governance token of the ShieldTech community in the future.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section-md-mxw-b-0 ov-h tc-light bg-theme-grad">
                <div className="container overflow-hidden">
                    <div className="nk-block nk-block-text-wrap">
                        <div className="row align-items-center gutter-vr-30px justify-content-lg-between justify-content-center">
                            <div className="col-lg-5 col-mb-10">
                                <div className="nk-block-img gfx-re-lgl py-5 py-lg-0">
                                    <div className="gfx-round-bg after"></div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/products/b.jpg'} alt="app" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-mb-10">
                                <div className="nk-block-text">
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="fz-5 text-justify">ShieldTech has also announced that “Empowering KYB’’ will be a key priority of ShieldTech and will build KYB as a killer product rather than a simple token, which is bound to further diversify the benefits that KYB holders can access. In the long run, KYB performs as the key to the entire ShieldTech ecosystem.</p>
                                    </ScrollAnimation>
                                    <div className="hr mgy-r"></div>
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <p className="fz-5 text-justify">With the development of DEX and ShieldTech, KYB will also be the underlying fuel and general token for future ShieldTech decentralized products. More details will likely be released in the near future.</p>                                        
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <div className="nk-ovm nk-ovm-fixed shape-z5">
            <div className="ovm-line"></div>
        </div>

        <footer className="nk-footer bg-theme-alt section-connect">
            <Subscibe />
            <Footer />
        </footer>

        <Preloader />    
    </div>
  );
};

export default KyberUltra;
