// src/components/Partners.js
import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Partners = () => {
  return (
    <section className="section bg-theme-dark tc-light" id="partners">
        <div className="container">
            <ScrollAnimation className="section-head text-center wide-auto-sm tc-light" animateIn="fadeInUp">
                <h2 className="title title-s4" title="Partners">Shield Tech Partners</h2>
            </ScrollAnimation>
            <div className="nk-block block-partners">
                <ul className="partner-list partner-list-s2 flex-wrap">
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/a-xs.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/b-xs.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/c-xs.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/d-xs.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/e-xs.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/f-xs.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/a-sm.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/b-sm.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/c-sm.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                    <li className="partner-logo-s2">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/partners/d-sm.png'} alt="partner" />
                        </ScrollAnimation>
                    </li>
                </ul>
            </div>
        </div>
    </section>
  );
};

export default Partners;
