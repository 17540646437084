// src/components/Token.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Token = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (window.ShieldApp) {
        window.ShieldApp.Chart.ChartJs();
      }
    }
  }, []);
  return (
    <section
      className="section section-tokensale tc-light bg-theme-dark-alt"
      id="token"
    >
      <div className="container overflow-hidden">
        <div className="section-head text-center wide-auto-sm">
          <ScrollAnimation animateIn="fadeInUp">
            <h2 className="title title-s4" title="TOKEN">
              Token Distribution
            </h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp">
            <p>
              Total supply of Kyber Ultra coin: <b>400,000,000</b>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp">
            <p>
              Kyber Ultra Is backed by the performance of the Shield Tech Group.
            </p>
          </ScrollAnimation>
        </div>
        <div class="nk-block nk-block-token mgb-m30">
          <div class="row align-items-center justify-content-between gutter-vr-50px">
            <div class="col-lg-5">
              <div class="token-info">
                <h4 class="title title-md mb-2 text-sm-center">
                  Token Information
                </h4>
                <table class="table table-s1 table-token">
                  <tbody>
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">Symbol</td>
                      <td class="table-des border-0 py-2 text-end">Kyber</td>
                    </tr>
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">Maximum Supply</td>
                      <td class="table-des border-0 py-2 text-end">
                        400,000,000
                      </td>
                    </tr>
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">
                        Initial Criculating Supply
                      </td>
                      <td class="table-des border-0 py-2 text-end">
                        400,000,000
                      </td>
                    </tr>
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">Price, Presale</td>
                      <td class="table-des border-0 py-2 text-end">$0.15</td>
                    </tr>
                    {/* <tr class="border-0">
                      <td class="table-head border-0 py-2">
                        Price, Private Sale Phase 1
                      </td>
                      <td class="table-des border-0 py-2 text-end">$0.20</td>
                    </tr>
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">
                        Price, Private Sale Phase 2
                      </td>
                      <td class="table-des border-0 py-2 text-end">$0.20</td>
                    </tr> */}
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">
                        Price, Public Sale Phase 1 (till 1-05-2024)
                      </td>
                      <td class="table-des border-0 py-2 text-end">$0.15</td>
                    </tr>

                    <tr class="border-0">
                      <td class="table-head border-0 py-2">
                        Price, Public Sale Phase 2 (after 1-05-2024)
                      </td>
                      <td class="table-des border-0 py-2 text-end">$0.20</td>
                    </tr>
                    <tr class="border-0">
                      <td class="table-head border-0 py-2">Platform</td>
                      <td class="table-des border-0 py-2 text-end">Ethereum</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-lg-7">
              <section class="ov-h section">
                <div class="nk-block nk-block-token">
                  <div class="tab-content text-center">
                    <div class="tab-pane fade show animate active">
                      <div class="token-alocate-item token-alocate-item-s1 shadow-none">
                        <ScrollAnimation
                          className="token-alocate-graph token-alocate-graph-s1"
                          animateIn="fadeInUp"
                        >
                          <canvas
                            class="chart-canvas chart-canvas-s1"
                            id="token-alocate-5"
                          ></canvas>
                        </ScrollAnimation>
                        <ul
                          class="chart-data-s1"
                          data-canvas="token-alocate-5"
                          data-canvas-type="doughnut"
                          data-border-color="#F5F6F9"
                        >
                          <li
                            data-color="#223fa8"
                            data-title="Token Sale"
                            data-amount="27"
                          ></li>
                          <li
                            data-color="#8fa0df"
                            data-title="Founders"
                            data-amount="30"
                          ></li>
                          <li
                            data-color="#4aa0f6"
                            data-title="Marketing"
                            data-amount="5"
                          ></li>
                          <li
                            data-color="#6ad9ac"
                            data-title="Advisors"
                            data-amount="3"
                          ></li>
                          <li
                            data-color="#4d6fe9"
                            data-title="Blockchain"
                            data-amount="10"
                          ></li>
                          <li
                            data-color="#15216d"
                            data-title="Partners"
                            data-amount="5"
                          ></li>
                          <li
                            data-color="#72a3f4"
                            data-title="Ecosystem"
                            data-amount="20"
                          ></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-lg-12">
              <div class="token-info mt-0 table-responsive">
                <table class="table table-s1 table-token text-center">
                  <thead>
                    <tr>
                      <th class="table-head">Distribution</th>
                      <th class="table-head">Price</th>
                      <th class="table-head">% of Kyber</th>
                      <th class="table-head"># of Kyber</th>
                      <th class="table-head">Hard Cap</th>
                      <th class="table-head">Max Contribution</th>
                      <th class="table-head">Vesting Period</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="table-des">Founders</td>
                      <td class="table-des">----</td>
                      <td class="table-des">30%</td>
                      <td class="table-des">120,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">
                        24 months, then unlock 10% monthly
                      </td>
                    </tr>
                    <tr>
                      <td class="table-des">Advisors</td>
                      <td class="table-des">----</td>
                      <td class="table-des">3%</td>
                      <td class="table-des">12,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">
                        12 months, then unlock 10% monthly
                      </td>
                    </tr>
                    <tr>
                      <td class="table-des">Partners</td>
                      <td class="table-des">----</td>
                      <td class="table-des">5%</td>
                      <td class="table-des">20,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                    </tr>
                    <tr>
                      <td class="table-des">Ecosystem</td>
                      <td class="table-des">----</td>
                      <td class="table-des">20%</td>
                      <td class="table-des">80,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                    </tr>
                    <tr>
                      <td class="table-des">Blockchain</td>
                      <td class="table-des">----</td>
                      <td class="table-des">10%</td>
                      <td class="table-des">40,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                    </tr>
                    <tr>
                      <td class="table-des">Marketing</td>
                      <td class="table-des">----</td>
                      <td class="table-des">5%</td>
                      <td class="table-des">20,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                    </tr>

                    <tr>
                      <td class="table-des">Token Sale</td>
                      <td class="table-des">----</td>
                      <td class="table-des">27%</td>
                      <td class="table-des">108,000,000</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                      <td class="table-des">----</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Token;
