// src/components/Enquries.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Enquries = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            
        }
    }, []);
    
  return (
    <section className="section section-contact bg-dark-alt">
        <div className="container">
            <div className="nk-block block-contact" id="contact">
                <div className="row justify-content-center g-0">
                    <div className="col-lg-5">
                        <div className="contact-wrap round split split-left split-lg-left bg-white">
                            <h5 className="title title-md">Enquiries? Get in touch.</h5>
                            <form className="nk-form-submit" action="#" method="post" novalidate="novalidate">
                                <div className="field-item">
                                    <input name="contact-name" type="text" className="input-line required" aria-required="true" />
                                    <label className="field-label field-label-line">Your Name</label>
                                </div>
                                <div className="field-item">
                                    <input name="contact-email" type="email" className="input-line required email" aria-required="true" />
                                    <label className="field-label field-label-line">Your Email</label>
                                </div>
                                <div className="field-item">
                                    <textarea name="contact-message" className="input-line input-textarea required" aria-required="true"></textarea>
                                    <label className="field-label field-label-line">Your Message</label>
                                </div>
                                <input type="text" className="d-none" name="form-anti-honeypot" value="" />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <button type="submit" className="btn btn-lg btn-info">Submit</button>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="form-results"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-wrap split split-right split-lg-right bg-genitian bg-theme tc-light">
                            <p className="fz-5">Connect with Shield Tech Group</p>
                            <p className="fz-5">Get in touch with our dedicated Teams.</p>
                            <div className="d-flex flex-column justify-content-between h-100">
                                <table className="table table-s1 table-token">
                                    <tbody>
                                        <tr>
                                            <td className="table-head tc-white">General</td>
                                            <td className="table-des">contact@shieldtechgroup.io</td>
                                        </tr>
                                        <tr>
                                            <td className="table-head tc-white">Regulatory</td>
                                            <td className="table-des">regulatory-inquiries@shieldtechgroup.io</td>
                                        </tr>
                                        <tr>
                                            <td className="table-head tc-white">Law Enforcement</td>
                                            <td className="table-des">lawenforcementglobal@shieldtechgroup.io</td>
                                        </tr>
                                        <tr>
                                            <td className="table-head tc-white">Press</td>
                                            <td className="table-des">press@shieldtechgroup.io</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Enquries;
