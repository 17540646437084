import "./App.css";

import { createWeb3Modal } from "@web3modal/wagmi/react";
import { walletConnectProvider, EIP6963Connector } from "@web3modal/wagmi";
import ScrollAnimation from "react-animate-on-scroll";

import {
  WagmiConfig,
  configureChains,
  createConfig,
  useAccount,
  useBalance,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { sepolia, mainnet } from "viem/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import ConnectButton from "./components/connectButton";
import BuyToken from "./components/buyToken";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "fe7c240cfbc7f89919a45415517da4a0";
// 2. Create wagmiConfig
const { chains, publicClient } = configureChains(
  [mainnet],
  [walletConnectProvider({ projectId }), publicProvider()]
);
// const { chains, publicClient } = configureChains([sepolia], [publicProvider()]);

const metadata = {
  name: "Web3Modal",
  description: "Connect wallet",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: false, metadata },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: metadata.name },
    }),
  ],
  publicClient,
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

export default function BuyTokens() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <div className="section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 text-center">
              <ScrollAnimation
                className="banner-caption wide-auto-xl pdb-s pdt-s tc-white"
                animateIn="fadeInUp"
              >
                <div className="cpn-head">
                  <h1 className="title title-md tc-white fz-3">
                    KYB Coin Sale
                  </h1>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-xl-8 col-lg-10">
              {/* <ScrollAnimation animateIn="fadeInUp"> */}
              <div className="card-buy-token">
                <div className="user-account">
                  <UserAccount />
                  <ConnectButton />
                </div>
                <BuyToken />
              </div>
              {/* </ScrollAnimation> */}
            </div>
          </div>
        </div>
      </div>
    </WagmiConfig>
  );
}

function UserAccount() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data } = useBalance({
    address,
  });
  if (isConnecting) return <div className="address">Connecting…</div>;
  if (isDisconnected) return <div className="address">Offline</div>;
  return (
    <div className="address">
      Address: {address?.replace(address?.slice(4, 38), "*")}
      <br />
      Balance: {data?.formatted} {data?.symbol}
    </div>
  );
}
