/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import Select from "react-select";
import { useContractWrite, useAccount, useWaitForTransaction } from "wagmi";
import { parseEther } from "viem";
import { createStandaloneToast } from "@chakra-ui/toast";
import axios from "axios";

import EthIcon from "./../assets/eth1.svg";
import USDTIcon from "./../assets/usdt.svg";
import BNBIcon from "./../assets/bnb.svg";
import FTMIcon from "./../assets/ftm.svg";
import WBTCIcon from "./../assets/wbtc.svg";
import ethABI from "./../contract/ethABI.json";
import erc20ABI from "../contract/ERC20ABI";
import { ERC_20, ETH_ADDRESS } from "../contract/addresses";
import { FiCopy } from "react-icons/fi"; // Import copy icon from react-icons library

const options = [
  { value: "eth", label: "Eth" },
  { value: "usdt", label: "USDT" },
  // { value: "bnb", label: "BNB" },
  // { value: "ftm", label: "FTM" },
  // { value: "wbtc", label: "WBTC" },
];

const icons = {
  eth: EthIcon,
  usdt: USDTIcon,
  bnb: BNBIcon,
  ftm: FTMIcon,
  wbtc: WBTCIcon,
};

const { ToastContainer, toast } = createStandaloneToast();

export default function BuyToken() {
  const [selectedOption, setSelectedOption] = useState("ETH");
  const [tokenValue, setTokenValue] = useState(0);

  const [kybAmount, setKybAmount] = useState(0.0001);
  const [tokenToKYB, setTokenToKYB] = useState(0);
  const kybToUsdtRate = 0.15;
  const usdtToKybRate = 1 / kybToUsdtRate;
  const [copiedText, setCopiedText] = useState("");

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text); // Set copied text for indication

    // Clear the copied text after 3 seconds
    setTimeout(() => {
      setCopiedText("");
    }, 3000);
  };

  // const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const notify = ({ status, description, title }) => {
    toast({
      duration: 2500,
      status,
      title,
      description,
    });
  };

  const {
    isLoading,
    write: depositEth,
    error,
    isSuccess,
  } = useContractWrite({
    address: ETH_ADDRESS,
    abi: ethABI,
    functionName: "depositEth",
  });

  const {
    isLoading: depositLoading,
    write: depositOther,
    error: otherError,
    isSuccess: otherSuccess,
  } = useContractWrite({
    address: ETH_ADDRESS,
    abi: ethABI,
    functionName: "depositToken",
  });

  const {
    isLoading: approveLoading,
    write: approveTokens,
    error: approveError,
    data: approveData,
  } = useContractWrite({
    address: ERC_20, // make address object chain?.id,
    abi: erc20ABI,
    functionName: "approve",
  });

  const { isSuccess: waitSuccess, isLoading: waitLoading } =
    useWaitForTransaction({
      hash: approveData?.hash,
    });

  const handleBuyToken = () => {
    if (selectedOption?.value === "eth") {
      depositEth({
        value: parseEther(tokenValue),
      });
    } else {
      approveTokens({
        args: [ETH_ADDRESS, parseEther(tokenValue)],
      });
    }
  };

  useEffect(() => {
    if (error?.message || approveError?.message || otherError?.message)
      notify({
        status: "error",
        title: error?.message || approveError?.message || otherError?.message,
      });
  }, [error?.message, approveError?.message, otherError?.message]);

  useEffect(() => {
    if (isSuccess || otherSuccess)
      notify({
        status: "success",
        title: "Token buy successfully!",
      });
  }, [isSuccess, otherSuccess]);

  useEffect(() => {
    if (waitSuccess) {
      console.log("depositOther");
      depositOther({
        args: [ERC_20, parseEther(tokenValue)],
      });
    }
  }, [waitSuccess]);

  const Control = ({ children, ...props }) => {
    const selected = props.getValue()?.[0];
    return (
      <div className="select">
        <img className="icon" src={icons[selected?.value]} /> {children}
      </div>
    );
  };

  const calculateUsdtAmount = () => {
    // const fetchData = async (data) => {
    //   try {
    //     let jsonData = await axios.get(data);
    //     // setData(jsonData);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };

    // fetchData("https://dev.api.shieldtechgroup.io/kybToUSD");
    return kybToUsdtRate.toFixed(4);
  };

  const fetchData = async () => {
    try {
      if (!selectedOption.value) {
        return;
      }

      const url = `https://api.shieldtechgroup.io/tokenToKYB?amount=${tokenValue}&tokenName=${selectedOption.value}&network=ethereum`;
      const response = await axios.get(url);
      setTokenToKYB(Number(response.data.price).toFixed(4));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption.value, tokenValue]);

  const calculateReceivedKyb = () => {
    // fetchData();
    let i = 1;
    return tokenToKYB;
  };

  const CustomOption = ({ children, innerProps, isDisabled, data }) => {
    return !isDisabled ? (
      <div {...innerProps} className="option">
        <img className="icon" src={icons[data?.value]} />
        {children}
      </div>
    ) : null;
  };
  return (
    <>
      <div className="data-row">
        <input
          className="input"
          value={tokenValue}
          placeholder="Enter Amount Want to Send"
          onChange={(e) => setTokenValue(e.target.value)}
          type="number"
        />
        <Select
          onChange={setSelectedOption}
          options={options}
          value={selectedOption}
          components={{ Control, Option: CustomOption }}
        />
      </div>
      <div className="token-calculator-card address">
        <h3>
          <b className="underline">Token Calculator</b>
        </h3>

        <div>
          <p className="calculatorText">
            {" "}
            {`1 KYB = ${calculateUsdtAmount()} USDT`}
          </p>
          <p className="calculatorTextSending">
            {" "}
            {`Send ${tokenValue}  ${(selectedOption.value
              ? selectedOption.value
              : selectedOption
            ).toUpperCase()} and receive ${calculateReceivedKyb()} KYB`}{" "}
          </p>
        </div>
      </div>
      <button
        disabled={!depositEth || !tokenValue || !selectedOption || !isConnected}
        className="btn btn2"
        onClick={handleBuyToken}
      >
        {isLoading || depositLoading || approveLoading || waitLoading
          ? "Check Wallet"
          : "Buy Tokens"}
      </button>

      <div className="container">
        <h5 className="kyberUl-heading">
          Import / Add KyberUltra Network in your Wallet
        </h5>
        <div className="card">
          <div className="info">
            <p>
              <strong>Name:</strong> KyberUltra{" "}
              <button
                className="copy-button"
                onClick={() => copyToClipboard("KyberUltra")}
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
            <p>
              <strong>RPC URL: </strong> https://mainnet-rpc.shieldtechgroup.is{" "}
              <button
                className="copy-button"
                onClick={() =>
                  copyToClipboard("https://mainnet-rpc.shieldtechgroup.is")
                }
              >
                <FiCopy className="fiCopy" />
              </button>
              {/* </a> */}
            </p>
            <p>
              <strong>ChainId:</strong> 1080{" "}
              <button
                className="copy-button"
                onClick={() => copyToClipboard("1080")}
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
            <p>
              <strong>Currency:</strong> KYB{" "}
              <button
                className="copy-button"
                onClick={() => copyToClipboard("KYB")}
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
            <p>
              <strong>Explorer:</strong> https://kyberscan.shieldtechgroup.is{" "}
              <button
                className="copy-button"
                onClick={() =>
                  copyToClipboard("https://kyberscan.shieldtechgroup.is")
                }
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
          </div>
        </div>
        {copiedText && <p>Copied: {copiedText}</p>}
      </div>

      <ToastContainer />
    </>
  );
}
