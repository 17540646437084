// src/components/Team.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Team = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section bg-theme-dark tc-light ov-h" id="team">
        <div className="container">
            <ScrollAnimation className="section-head text-center wide-auto-sm tc-light" animateIn="fadeInUp">
                <h2 className="title title-s4" title="our team">ONE PURPOSE ONE GOAL ONE GLOBAL TEAM</h2>
                <p>Building a world-changing platform is an immense task. But through our Team’s determination and commitment, it has been streamlined and made it possible.</p>
            </ScrollAnimation>
        </div>
    </section>
  );
};

export default Team;
