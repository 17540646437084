// src/components/Benefits.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Benefits = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section">
        <div className="container overflow-hidden">
            <div className="nk-block nk-block-lg nk-block-features-s2">
                <div className="row align-items-center flex-row-reverse gutter-vr-30px">
                    <div className="col-md-6">
                        <ScrollAnimation className="gfx " animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-s-light.png'} alt="gfx" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-6">
                        <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                            <h6 className="title title-xs title-s1 tc-primary">Staking coins</h6>
                            <h2 className="title text-white">What is Staking coins?</h2>
                            <p className="text-justify">All blockchains have one thing in common: transactions need to get validated. Bitcoin for example does this in a process called mining which is known to use a lot of electricity <b>(Proof-of-Work)</b>. There are, though, other consensus mechanisms that are used for validation.</p>
                            <p className="text-justify"><b>Proof-of-Stake (PoS)</b> is one such consensus mechanism that has several variations of its own, as well as some hybrid models. To keep things simple, we will refer to all of these as staking. Coin staking gives currency holders some decision power on the network. By staking coins, you gain the ability to vote and generate an income. It is quite similar to how someone would receive interest for holding money in a bank account or giving it to the bank to invest.</p>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div className="nk-block nk-block-lg nk-block-features-s2">
                <div className="row align-items-center gutter-vr-30px">
                    <div className="col-md-6">
                        <ScrollAnimation className="gfx" animateIn="fadeInUp">
                            <img src={process.env.PUBLIC_URL + '/assets/images/gfx/gfx-u-light.png'} alt="gfx" />
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-6">
                        <ScrollAnimation className="nk-block-text card bg-theme-alt" animateIn="fadeInUp">
                            <h6 className="title title-xs title-s1 tc-primary">Benefits</h6>
                            <h2 className="title text-white">What are some of the benefits?</h2>
                            <p className="text-justify">One of the major benefits for staking coins is that it removes the need for continuously purchasing expensive hardware and consuming energy.</p>
                            <p className="text-justify">The system offers guaranteed returns and a predictable source of income unlike the proof-of-work system where coins are rewarded through a mathmatetical process with a low probability of paying out. Another benefit is that the value of your staked coins doesn't depreciate unlike with ASICs and other mining hardware. Staked coins are only affected by market price fluctuations.</p>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Benefits;
