// src/components/Why.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Why = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  return (
    <section className="section bg-alternet tc-light">
      <div className="nk-block nk-block-features overflow-hidden">
        <div className="container">
          <div className="row align-items-center gutter-vr-30px">
            <div className="col-lg-4">
              <ScrollAnimation className="nk-block-text" animateIn="fadeInUp">
                <h2 className="title title-thin text-white">
                  WE HELP WORKPLACE TEAMS COLLABORATE SECURELY.
                </h2>
              </ScrollAnimation>
            </div>
            <div className="col-lg-8">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <ScrollAnimation
                    className="feature card card-s2 ov-h"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-md text-dark">MISSION</h5>
                      <p>
                        Our mission is to give organisations a secure and
                        trusted alternative to conventional workplace
                        communication platforms. We are fully committed to
                        developing the most secure communication app to help
                        teams stay connected, securely.
                      </p>
                    </div>
                    <div className="feature-icon feature-icon-bg">
                      <em className="icon ikon ikon-paricle-alt tc-info pt-4"></em>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-sm-6">
                  <ScrollAnimation
                    className="feature card card-s2 ov-h p-5"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-md text-dark">VISION</h5>
                      <p>
                        Our vision is a world where people can work securely,
                        anytime and anywhere. As the way we work evolves and our
                        reliance on communication technology increases, Kyber is
                        committed to helping organisations protect their data
                        today and in the future.
                      </p>
                    </div>
                    <div className="feature-icon feature-icon-bg">
                      <em className="icon ikon ikon-bulb-2 tc-info pt-4"></em>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    className="feature card card-s2 ov-h p-5"
                    animateIn="fadeInUp"
                  >
                    <div className="feature-text">
                      <h5 className="title title-md text-dark">VALUES</h5>
                      <p>
                        In our core values, curiosity drives us to explore the
                        unknown, resilience pushes us relentlessly, courage
                        allows us to embrace challenges, impact motivates us to
                        make a meaningful difference, and connection fosters our
                        collaborative spirit for achieving greatness together.
                      </p>
                    </div>
                    <div className="feature-icon feature-icon-bg">
                      <em className="icon ikon ikon-user tc-info pt-4"></em>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container overflow-hidden section">
        <div className="section-head text-center wide-auto-sm wide-auto">
          <h2 className="title">WHY KYBERCHAT</h2>
        </div>
        <div className="row align-items-center justify-content-center gutter-100px">
          <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
            <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
              <div className="feature-img-xl mb-4">
                <em className="icon icon-lg fa fa-lock tc-info"></em>
              </div>
              <div className="feature-text">
                <h5 className="title title-sm">BETTER SECURITY</h5>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
            <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
              <div className="feature-img-xl mb-4">
                <em className="icon icon-lg fa fa-shield-alt tc-info"></em>
              </div>
              <div className="feature-text">
                <h5 className="title title-sm">SECURE COMMUNICATION CHANNEL</h5>
                <p>
                  Keep teams connected wherever they work with the confidence of
                  secure and private communication.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
            <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
              <div className="feature-img-xl mb-4">
                <em className="icon icon-lg fa fa-share tc-info"></em>
              </div>
              <div className="feature-text">
                <h5 className="title title-sm">MESSAGING & FILE SHARING</h5>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        <div className="row align-items-center justify-content-center gutter-100px mgt-r">
          <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
            <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
              <div className="feature-img-xl mb-4">
                <em className="icon icon-lg fa fa-video tc-info"></em>
              </div>
              <div className="feature-text">
                <h5 className="title title-sm">VOICE & VIDEO CALLING</h5>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
            <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round">
              <div className="feature-img-xl mb-4">
                <em className="icon icon-lg fa fa-tv tc-info"></em>
              </div>
              <div className="feature-text">
                <h5 className="title title-sm">SCREEN SHARING</h5>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className="col-lg-4 col-mb-10" animateIn="fadeInUp">
            <div className="feature feature-center feature-pd-lg boxed-sm bg-theme-alt shadow round center-lg">
              <div className="feature-img-xl mb-4">
                <em className="icon icon-lg fa fa-microphone tc-info"></em>
              </div>
              <div className="feature-text">
                <h5 className="title title-sm">VOICE NOTES</h5>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
};

export default Why;
