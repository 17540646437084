// src/components/Footer.js
import React from "react";

const Footer = () => {
    const currentYear = new Date().getFullYear();

  return (
    <div className="section section-footer pb-0 tc-light bg-transparent">
        <div className="container">
            <div className="nk-block block-footer">
                <div className="row">
                    <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                        <div className="wgs wgs-menu">
                            <h6 className="wgs-title">Company</h6>
                            <div className="wgs-body">
                                <ul className="wgs-links">
                                    <li><a href="#">About Us</a></li>
                                    <li><a href="#">Meet Our Team</a></li>
                                    <li><a href="#">Advisors</a></li>
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Insights</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                        <div className="wgs wgs-menu">
                            <h6 className="wgs-title">Legal</h6>
                            <div className="wgs-body">
                                <ul className="wgs-links">
                                    <li><a href="#">Terms &amp; Conditions</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Terms of Sales</a></li>
                                    <li><a href="#">Whitepaper</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 mb-4 mb-sm-0">
                        <div className="wgs wgs-menu">
                            <h6 className="wgs-title">Quick Links</h6>
                            <div className="wgs-body">
                                <ul className="wgs-links">
                                    <li><a href="#">Ecosystems</a></li>
                                    <li><a href="#">Tokens</a></li>
                                    <li><a href="#">Roadmaps</a></li>
                                    <li><a href="#">Faqs</a></li>
                                    <li><a href="#">Login</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4 mb-lg-0 order-lg-first">
                        <div className="wgs wgs-text">
                            <div className="wgs-body w-75">
                                <a href="#" className="wgs-logo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/logo/gradient-logo.png'} srcSet={process.env.PUBLIC_URL + '/assets/images/logo/gradient-logo.png'} alt="logo" />
                                </a>
                                <p className="text-justify">We are an accomplished cybersecurity consulting firm with expertise in the financial technology and crypto security domains. As pioneers in the crypto industry, we proudly operate under the name <b>SHIELD TECH GROUP</b>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="wgs wgs-text text-center mb-3">
                            <ul className="social pdb-m justify-content-center">
                                <li><a href="#"><em className="social-icon fab fa-facebook-f"></em></a></li>
                                <li><a href="#"><em className="social-icon fab fa-twitter"></em></a></li>
                                <li><a href="#"><em className="social-icon fab fa-youtube"></em></a></li>
                                <li><a href="#"><em className="social-icon fab fa-github"></em></a></li>
                            </ul>
                            <div className="copyright-text copyright-text-s3 pdt-m">
                                <p><span className="d-sm-block">Copyright &copy; {currentYear}, Shieldtech.</span>All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
};

export default Footer;
