// src/components/About.js
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const About = () => {
  return (
    <div className="about-section">
      <section
        className="section mask-c-blend-dark bg-theme-dark-alt tc-light ov-h"
        id="ico"
      >
        <div className="container">
          <div className="nk-block nk-block-text-wrap">
            <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
              <div className="col-mb-9 col-sm-7 col-md-6 col-lg-5 order-md-last">
                <ScrollAnimation animateIn="fadeInUp">
                  <div className="nk-block-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/light/gfx-z-a.png"
                      }
                      alt="app"
                    />
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-sm-10 col-md-6 text-center text-md-start">
                <div className="nk-block-text">
                  <ScrollAnimation animateIn="fadeInUp">
                    <h2 className="title title-lg text-center fz-3">
                      Experience Seamless Management
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <h4 className="text-center">
                      of your Crypto Assets with user-friendly WEB3 apps,
                      <br />
                      Ensuring utmost Security and Privacy.
                      <br />
                    </h4>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <p className="lead leadc">
                      We have developed a suite of Security Solutions Customized
                      to address the specific use cases required by the{" "}
                      <b>WEB3 </b>
                      environment.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <a
                      href="https://www.youtube.com/watch?v=SSo_EIwHSd4"
                      className="video-popup btn-play-wrap"
                    >
                      <div className="btn-play btn-play-sm btn-play-s2">
                        <em className="btn-play-icon"></em>
                      </div>
                      <div className="btn-play-text">
                        <span className="text-sm">Watch Video</span>
                        <span className="text-xs">What and How it work</span>
                      </div>
                    </a>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-theme-dark tc-light ov-h">
        <div className="container">
          <div className="nk-block nk-block-features-2">
            <div className="row align-items-center gutter-vr-30px justify-content-center justify-content-md-between">
              <div className="col-mb-9 col-sm-7 col-md-6 col-lg-5">
                <ScrollAnimation animateIn="fadeInUp">
                  <div className="gfx py-4">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/light/gfx-z-b.png"
                      }
                      alt="gfx"
                    />
                  </div>
                </ScrollAnimation>
              </div>
              <div className="col-sm-10 col-md-6 text-center text-md-start">
                <div className="nk-block-text">
                  <ScrollAnimation animateIn="fadeInUp">
                    <h4 className="title title-lg text-center">
                      Digital and Crypto Assets Custody Solutions for the WEB3
                    </h4>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <p className="lead text-justify">
                      We facilitate the integration of the decentralized economy
                      into business environments, delivering recognized and
                      truly secure user experiences that enable mass adoption.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <p className="text-justify">
                      We are creating a secure environment using our proprietary
                      blockchain to meet and offer a wide range of services.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <ul className="list list-dot pdl-r pdb-s">
                      <li>Solutions for the Secure Communication</li>
                      <li>Exchanging Crypto</li>
                      <li>Storing Crypto</li>
                      <li>Staking Crypto</li>
                    </ul>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <p className="text-justify">
                      By integrating smart contracts and establishing multiple
                      cross bridges to various blockchains such as{" "}
                      <b>Ethereum</b>, <b>BTC</b>, <b>Ripple</b> and stable
                      coins. We aim to enhance connectivity within our
                      blockchain ecosystem.
                    </p>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp">
                    <p className="text-justify">
                      Currently, we are developing a Crypto Ecosystem that seeks
                      to eliminate mediator and perform Financial transactions
                      with absolute freedom.
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
