// src/pages/Exchange.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Particle from "../components/Particle";
import FastExchange from "../components/FastExchange";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ScrollAnimation from 'react-animate-on-scroll';
import $ from 'jquery';

function Exchange() {  
    
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
  
        }
    }, []);

  return (
    <div className="Exchange-page nk-wrap">

        <header className="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
            <Header />
            <div className="header-banner bg-theme-grad">
                <div className="nk-banner">
                    <div className="banner banner-page pb-0">
                        <div className="banner-wrap">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-9">
                                        <div className="banner-caption cpn tc-light text-center">
                                            <ScrollAnimation className="cpn-head" animateIn="fadeInUp" initiallyVisible={true}>
                                                <h2 className="title ttu">Exchange</h2>
                                                <p>
                                                    Shield Tech Instant swap model- a
                                                    KYC-free and Anonymous Solution.
                                                </p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nk-ovm bg-blend before-bottom before-h70 shape-o shape-contain shape-center-top"></div>

                <Particle />
            </div>
        </header>  

        <main className="nk-pages">
            <FastExchange />
        </main>

        <div className="nk-ovm nk-ovm-fixed shape-z5">
            <div className="ovm-line"></div>
        </div>

        <footer className="nk-footer bg-theme-alt section-connect">
            <Subscibe />
            <Footer />
        </footer>

        <Preloader />    
    </div>
  );
}

export default Exchange;