// Preloader.js
import React, { useEffect, useRef } from 'react';

const Preloader = () => {
    
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            if(window.ShieldApp) {
                window.ShieldApp.preLoader();
            }
        }
    }, []);
    

  return(
    <div className="preloader"><span className="spinner spinner-round"></span></div>
  )
};

export default Preloader;
