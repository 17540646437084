// src/components/Faqs.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Faqs = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            
        }
    }, []);
    
  return (
    <section className="section bg-theme tc-light" id="faqs">
        <div className="transform-line"></div>
        <div className="container">
            <ScrollAnimation className="section-head text-center wide-auto-sm" animateIn="fadeInUp">
                <h2 className="title title-s4" title="FAQS">Frequently Asked Questions</h2>
                <p className="">Below, you'll find a concise overview of Cryptocurrency, Bitcoin, and a few other aspects of the crypto field.</p>
                <p className="">If you seek more detailed information, please stay connected with us by completing the contact form provided above. Our support team will promptly respond to your inquiry.</p>
            </ScrollAnimation>
            <div className="nk-block overflow-hidden">
                <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-10">
                        <ul className="nav tab-nav tab-nav-line mgb-r"></ul>
                        <ScrollAnimation className="tab-content animated" animateIn="fadeInUp">
                            <div className="tab-pane fade show active" id="general-questions-19">
                                <div className="accordion accordion-s1 accordion-faq" id="faq-67">
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title" data-bs-toggle="collapse" data-bs-target="#faq-67-1"> What is Crypto? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-1" className="collapse show" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>Cryptocurrency is a digital or virtual currency that operates on distributed ledger technology called a blockchain and uses cryptography for security. It is decentralized and operates independently of a central bank.</p>

                                                <p>Unlike traditional currencies, cryptocurrencies are not backed by a physical commodity or government, and their value is determined by market demand and supply. Cryptocurrencies can be used to buy goods and services, transfer funds, and trade in markets. Popular cryptocurrencies include Bitcoin, Ethereum, Litecoin, Ripple, and Cronos.</p>

                                                <p>Many cryptocurrencies, like Bitcoin, are created through a process called mining, which involves solving complex mathematical equations to validate and record transactions on a blockchain. This mechanism is also called Proof of Work (PoW). Another consensus mechanism that has increased in popularity — as it is more energy efficient — is Proof of Stake (PoS). Instead of mining, PoS relies on network participants validating transactions. Ethereum, the second-largest cryptocurrency, uses this consensus mechanism.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-2"> What is Bitcoin? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-2" className="collapse" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>Bitcoin is a cryptocurrency that operates on a peer-to-peer (P2P) network. It was created in 2009 by an unknown person or group using the pseudonym Satoshi Nakamoto. Bitcoin is the first and most well-known cryptocurrency, and it has gained significant popularity and value since its creation.</p>

                                                <p>Unlike traditional fiat currency, which is controlled by central banks and governments, Bitcoin operates independently of any central authority. Transactions are verified and recorded on the blockchain, which is a distributed ledger that maintains a permanent and transparent record of all transactions.</p>

                                                <p>Bitcoin can be bought, sold, and exchanged on various cryptocurrency exchanges, and it can be used to purchase goods and services from merchants that accept Bitcoin as a form of payment. The supply of bitcoins is limited to 21 million units, and new bitcoins are created through mining, which involves using specialized computer hardware to solve complex mathematical equations.</p>

                                                <p>Bitcoin is known for its high volatility, and its value can fluctuate rapidly in response to market conditions, news events, and other factors. Many traders, including institutional investors, see Bitcoin as a store of value and a way to participate in the growing cryptocurrency ecosystem.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-3"> Where to buy Crypto? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-3" className="collapse" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>There are several ways to buy cryptocurrencies, including:</p>
                                                <ul className="list list-number">
                                                    <li><b>Brokerage services</b>: Crypto brokers allow users to simply buy and sell cryptocurrencies.</li>
                                                    <li><b>Cryptocurrency exchanges</b>: These are online platforms where users can buy, sell, and trade cryptocurrencies using fiat currency or other cryptocurrencies. They offer more complex functions compared to a crypto brokerage, adding trading instruments like crypto derivatives.</li>
                                                    <li><b>Peer-to-peer (P2P) marketplaces</b>: These are platforms where buyers and sellers can directly trade cryptocurrencies without the involvement of a third-party exchange. This is also known as DeFi, short for decentralized finance.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-4"> How to buy Crypto? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-4" className="collapse" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>To buy crypto, follow these general steps:</p>
                                                <ul className="list list-number">
                                                    <li>Choose a crypto platform to use.</li>
                                                    <li>Create an account on the chosen platform by providing personal information and ID verification, also known as ‘Know Your Customer’ (KYC) procedures.</li>
                                                    <li>Deposit fiat currency or another cryptocurrency into the newly created account. We will supports bank transfers, credit cards, debit cards, and cryptocurrency transfers to buy crypto, depending on region.</li>
                                                    <li>Navigate to the ‘Buy’ section of the  Exchange or App and select the crypto to buy.</li>
                                                    <li>Enter the amount of cryptocurrency to buy and confirm the transaction.</li>
                                                    <li>The crypto will be deposited into the account. From here, it can be transferred to other crypto wallets or converted back to fiat currency and paid out to a bank account.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-5"> How to buy Bitcoin? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-5" className="collapse" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>Users can buy Bitcoin from a cryptocurrency exchange, a crypto brokerage service  or a peer-to-peer (P2P) marketplace. Here are some general steps to buying bitcoin from an exchange or brokerage service:</p>
                                                <ul className="list list-number">
                                                    <li>Choose a reputable cryptocurrency platform that supports Bitcoin trading.</li>
                                                    <li>Create an account on the chosen platform and complete the KYC verification process, which may require providing personal identification documents.</li>
                                                    <li>Fund an account using a bank transfer, credit/debit card, or other cryptocurrency, depending on region.</li>
                                                    <li>Navigate to the ‘Buy’ section of the platform and select Bitcoin as the cryptocurrency to buy.</li>
                                                    <li>Enter the amount of bitcoin to buy, or the amount of fiat or cryptocurrency to spend.</li>
                                                    <li>Review the transaction details and confirm the purchase.</li>
                                                    <li>Once the transaction is complete, the bitcoin will be deposited into the chosen account. From here, the funds can be transferred to other crypto wallets or converted back to fiat currency and paid out to a bank account.</li>
                                                </ul>
                                                <p>It is important to perform proper research and choose a reputable platform to buy cryptocurrencies.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-6"> How to trade Crypto? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-6" className="collapse" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>To trade cryptocurrency, follow these general steps:</p>
                                                <ul className="list list-number">
                                                    <li>Choose a cryptocurrency exchange that supports trading.</li>
                                                    <li>Create an account on the chosen platform and perform ID verification, known as KYC (‘Know Your Customer’).</li>
                                                    <li>Deposit funds into the newly created account using a supported payment method. (We will supports bank transfers and credit/debit cards)</li>
                                                    <li>Navigate to the trading section of the platform and select the cryptocurrency pair to trade.</li>
                                                    <li>Choose whether to buy or sell the cryptocurrency, and enter the amount to trade.</li>
                                                    <li>Set the preferred price and order type. There are several types of orders, including market orders, limit orders, stop orders, and crypto options, which allow users to buy or sell at a specific price or under certain conditions.</li>
                                                    <li>Submit the trade order and wait for it to be executed. Depending on market conditions, the trade may be filled immediately, or it may take time to be filled.</li>
                                                    <li>Monitor trades and adjust strategies as necessary.</li>
                                                    <li>back to fiat currency and paid out to a bank account.</li>
                                                </ul>
                                                <p>It is crucial to note that trading cryptocurrency carries risk, and it is important to trade only what you can afford to lose.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item accordion-item-s4 bg-theme-alt">
                                        <h5 className="accordion-title collapsed" data-bs-toggle="collapse" data-bs-target="#faq-67-7"> How to earn Crypto? <span className="accordion-icon accordion-icon-s2"></span></h5>
                                        <div id="faq-67-7" className="collapse" data-bs-parent="#faq-67">
                                            <div className="accordion-content">
                                                <p>There are several ways to earn cryptocurrency, including:</p>
                                                <ul className="list list-number">
                                                    <li><b className="link-underline">Mining</b>: Cryptocurrency mining involves using specialized computer hardware to solve complex mathematical equations that validate transactions on a blockchain network. Successful miners are rewarded with newly minted cryptocurrency for their efforts.</li>
                                                    <li><b className="link-underline">Staking/Lockups</b>: Staking and lockups involve holding or locking up a certain amount of cryptocurrency in a wallet or on a platform to support the operations of the blockchain network. Stakers are rewarded with new cryptocurrency as a form of interest for their support.</li>
                                                    <li><b className="link-underline">Trading</b>: Trading cryptocurrency involves buying and selling cryptocurrencies on exchanges or other trading platforms. Those who have a good understanding of market trends and are able to make informed trading decisions can earn profits through trading.</li>
                                                    <li><b className="link-underline">Airdrops</b>: Airdrops are free distributions of cryptocurrency to users who meet certain criteria or participate in promotional activities.</li>
                                                    <li><b className="link-underline">Crypto Projects</b>: Some blockchain projects offer rewards or bounties for users who contribute to their development or community. This can include activities like bug bounties, testing, or content creation.</li>
                                                </ul>
                                                <p>It's important to note that each method of earning cryptocurrency carries its own risks and rewards. It is recommended to carefully research cryptocurrencies and understand the process before buying. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Faqs;
