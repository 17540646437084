// src/components/Particle.js
import React, { useEffect, useRef } from 'react';

const Particle = () => {
    
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            if(window.ShieldApp) {
                window.ShieldApp.particles();
            }
        }
    }, []);
    

  return(
    <div id="particles-bg" className="particles-container particles-bg" data-pt-base="#00c0fa" data-pt-base-op=".3" data-pt-line="#2b56f5" data-pt-line-op=".5" data-pt-shape="#00c0fa" data-pt-shape-op=".2"></div>
  )
};

export default Particle;
