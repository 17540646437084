// src/components/Subscibe.js
import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const Subscibe = () => {
  return (
    <div className="section section-m pb-0 tc-light ov-h">
      <div className="container py-4">
        <div className="nk-block pb-lg-5">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 col-md-9">
              <ScrollAnimation className="wide-auto-sm section-head section-head-sm pdb-r" animateIn="fadeInUp">
                <h4 className="title title-md">Don't miss out, Stay updated</h4>
              </ScrollAnimation>
              <form action="#" className="nk-form-submit" method="post">
                <ScrollAnimation className="field-inline field-inline-round field-inline-s2-sm bg-theme-dark-alt shadow-soft" animateIn="fadeInUp">
                  <div className="field-wrap">
                    <input className="input-solid input-solid-md required email" defaultValue="" type="text" name="contact-email" placeholder="Enter your email" />
                    <input type="text" className="d-none" name="form-anti-honeypot" defaultValue="" />
                  </div>
                  <div className="submit-wrap">
                    <button className="btn btn-md btn-round btn-info h-100">Subscribe</button>
                  </div>
                </ScrollAnimation>
                <div className="form-results"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-ovm shape-contain shape-center-top shape-t"></div>
    </div>
  );
};

export default Subscibe;
