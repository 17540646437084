import React from "react";
import "./../App.css"; // Import your CSS file for styling

const KyberUltraNetwork = () => {
  return (
    <div className="container">
      <h1 className="kyberUl-heading">
        Import / Add KyberUltra Network in your Wallet
      </h1>
      <div className="card">
        <div className="info">
          <p>
            <strong>Name:</strong> KyberUltra
          </p>
          <p>
            <strong>RPC URL:</strong>{" "}
            <a href="https://mainnet-rpc.shieldtechgroup.is">
              https://mainnet-rpc.shieldtechgroup.is
            </a>
          </p>
          <p>
            <strong>ChainId:</strong> 1080
          </p>
          <p>
            <strong>Currency:</strong> KYB
          </p>
          <p>
            <strong>Explorer:</strong>{" "}
            <a href="https://kyberscan.shieldtechgroup.is">
              https://kyberscan.shieldtechgroup.is
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KyberUltraNetwork;
