// src/components/FastExchange.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const FastExchange = () => {
  return (
    <section className="section bg-theme">
      <div className="container overflow-hidden">
        <div className="section-head text-center wide-auto tc-light">
          <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
            <h2 className="title">Fast Exchange</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" initiallyVisible={true}>
            <p>Shield Tech Instant swap model- a KYC-free and Anonymous Solution.</p>
          </ScrollAnimation>
        </div>
        <div className="nk-block">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <ScrollAnimation className="card card-lg bg-light" animateIn="fadeIn">
                <div className="row align-items-center">
                    <div className="col-md-12">
                      <link rel="stylesheet" type="text/css" href="https://letsexchange.io/widget_lets.css" />
                      <div className="lets-widget" id="lets_widget_VVDh6ZS6QXmTavbO">
                          <iframe title="Fast Exchange" src="https://letsexchange.io/v2/widget?affiliate_id=VVDh6ZS6QXmTavbO&is_iframe=true" 
                            width="100%" height="100%" frameBorder="0" allow="clipboard-read; clipboard-write" 
                            tabindex="-1"></iframe>
                      </div>
                      <script src="https://letsexchange.io/init_widget.js"></script>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FastExchange;