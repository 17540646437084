// src/components/FeatureServices.js
import React, { useEffect, useRef } from "react";
import ScrollAnimation from 'react-animate-on-scroll';

const FeatureServices = () => {

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

        }
    }, []);

  return (
    <section className="section bg-dark tc-light">
        <div className="container">
            <div className="boxed boxed-xl bg-theme-alt round-lg">
                <div className="row gutter-vr-30px">
                    <div className="col-lg-6">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-paricle-alt"></em></div></div>
                            <div className="feature-text text-justify">
                                <h5 className="title title-md">Fast Exchange</h5>
                                <p>Shield Tech Swap Model - a KYC-free and Anonymous Solution.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-shiled-3"></em></div></div>
                            <div className="feature-text text-justify">
                                <h5 className="title title-md">Secure Chat</h5>
                                <p>ShieldTech Protect your personal information and details with our robust privacy measures.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-wallet"></em></div></div>
                            <div className="feature-text text-justify">
                                <h5 className="title title-md">Shield Wallet</h5>
                                <p>The shield wallet will be a gateway into private decentralised transactions and communications.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-globe"></em></div></div>
                            <div className="feature-text text-justify">
                                <h5 className="title title-md">Shield VPN</h5>
                                <p>A ultra secure and safe and anonymous Decentralised VPN running on the SHIELDTECH network.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-wallet-digital"></em></div></div>
                            <div className="feature-text text-justify">
                                <h5 className="title title-md">Staking</h5>
                                <p>Staking contributes to a greener approach to cryptocurrency by reducing energy consumption.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-data-server"></em></div></div>
                            <div className="feature-text text-justify">
                                <h5 className="title title-md">Shield DEX</h5>
                                <p>Is a decentralized exchange – is a crypto exchange that operates on a decentralized platform.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="row d-flex">
                    <div className="col-lg-12">
                        <ScrollAnimation className="feature feature-inline feature-s11" animateIn="fadeInUp">
                            <div className="feature-icon feature-icon-lg-s3"><div className="feature-icon dot"><em className="icon ikon ikon-cash"></em></div></div>
                            <div className="featu text-justifyre-text">
                                <h5 className="title title-md">Spending</h5>
                                <p>With proud we announce we are working and building a Crypto Debit Card Program.</p>
                                <ul className="list list-dot feature-inline mgl-0">
                                    <li>No Annual Fees</li>
                                    <li className="mgl-r">The only crypto Card you need</li>
                                    <li className="mgl-r">Top up with Crypto </li>
                                </ul>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default FeatureServices;
