// src/pages/Home.js
import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Particle from "../components/Particle";
import PreSale from "../components/PreSale";
import About from "../components/About";
import Swap from "../components/Swap";
import Token from "../components/Token";
import Roadmap from "../components/Roadmap";
import Partners from "../components/Partners";
import Subscibe from "../components/Subscibe";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ScrollAnimation from "react-animate-on-scroll";
import BuyTokens from "./buyToken/BuyTokens";
import KyberUltraNetwork from "../components/KyberUltraNetwork";
import $ from "jquery";

function Home() {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
    }
  }, []);

  return (
    <div className="Home-page nk-wrap">
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
        id="header"
      >
        <Header />

        <div className="header-banner bg-theme-dark">
          <div className="nk-banner">
            <div className="banner banner-mask-fix banner-fs banner-single tc-light">
              <div className="banner-wrap">
                <div className="container">
                  <div className="row align-items-center justify-content-center gutter-vr-30px">
                    <div className="col-lg-6 order-lg-last">
                      <ScrollAnimation
                        className="banner-gfx banner-gfx-re-s3 text-center"
                        animateIn="fadeInUp"
                        initiallyVisible={true}
                      >
                        <img
                          className="w-75"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/header/gfx-e.png"
                          }
                          alt="header"
                        />
                      </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                      <div className="banner-caption wide-auto text-center text-lg-start">
                        <div className="cpn-head mt-0">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            initiallyVisible={true}
                          >
                            <h5>Welcome to</h5>
                          </ScrollAnimation>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            initiallyVisible={true}
                          >
                            <h1 className="title title-xl-2 title-semibold">
                              Shield Tech
                            </h1>
                          </ScrollAnimation>
                        </div>
                        <div className="cpn-text cpn-text-s1">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            initiallyVisible={true}
                          >
                            <p className="lead text-justify">
                              We are a Cryptocurrency Platform that specialized
                              in developing
                              <b> Secure</b>,<b> Decentralized</b> and{" "}
                              <b>Scalable </b>
                              Applications.
                            </p>
                          </ScrollAnimation>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            initiallyVisible={true}
                          >
                            <ul className="list list-check">
                              <li>Communication</li>
                              <li>Secure Exchanges</li>
                              <li>Financial Services</li>
                            </ul>
                          </ScrollAnimation>
                        </div>
                        <div className="cpn-btns">
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            initiallyVisible={true}
                          >
                            <ul className="btn-grp">
                              <li>
                                <a
                                  href="#"
                                  className="btn btn-md btn-info btn-round"
                                >
                                  Whitepaper
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#token"
                                  className="btn btn-md btn-info btn-info-alternet btn-round"
                                >
                                  Token Distribution
                                </a>
                              </li>
                            </ul>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-ovm mask-c-dark shape-v mask-contain-bottom"></div>

          <Particle />
        </div>
      </header>

      <main className="nk-pages">
        <PreSale />
        <BuyTokens />
        {/* <KyberUltraNetwork /> */}
        <About />
        <Swap />
        <Token />
        <Roadmap />
        <Partners />
      </main>

      <div className="nk-ovm nk-ovm-fixed shape-z5">
        <div className="ovm-line"></div>
      </div>

      <footer className="nk-footer bg-theme-alt section-connect">
        <Subscibe />
        <Footer />
      </footer>

      <Preloader />
    </div>
  );
}

export default Home;
